import React from 'react';

const DropFlower = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="80.252"
    height="92.125"
    viewBox="0 0 80.252 92.125"
  >
    <defs>
      <clipPath id="clip-path-drop-flower">
        <path id="Tracé_972" data-name="Tracé 972" d="M0,0H80.252V-92.125H0Z" fill="none" />
      </clipPath>
    </defs>
    <g id="Picto_peaux_sensibles" data-name="Picto peaux sensibles" transform="translate(0 92.125)">
      <g id="Groupe_1222" data-name="Groupe 1222" clipPath="url(#clip-path-drop-flower)">
        <g id="Groupe_1198" data-name="Groupe 1198" transform="translate(27.681 -10.612)">
          <path
            id="Tracé_948"
            data-name="Tracé 948"
            d="M0,0A26.238,26.238,0,0,1-22.723-39.357L0-78.713,22.722-39.357A26.844,26.844,0,0,1,24.629-35.2c.32-.567.637-1.07.945-1.54a28.518,28.518,0,0,0-1.639-3.318L0-81.512-23.935-40.057a27.361,27.361,0,0,0,0,27.638A27.36,27.36,0,0,0,0,1.4,27.282,27.282,0,0,0,20.095-7.3c-.5-.1-1.007-.258-1.521-.4A25.861,25.861,0,0,1,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1199" data-name="Groupe 1199" transform="translate(27.681 -12.011)">
          <path
            id="Tracé_949"
            data-name="Tracé 949"
            d="M0,0A24.584,24.584,0,0,1-21.51-12.42a24.581,24.581,0,0,1,0-24.839L0-74.517,21.51-37.259a25.194,25.194,0,0,1,2.232,5.2c.286-.616.584-1.208.887-1.746a26.8,26.8,0,0,0-1.907-4.158L0-77.315-22.723-37.958A26.238,26.238,0,0,0,0,1.4,25.858,25.858,0,0,0,18.574-6.3c-.515-.144-1.027-.272-1.545-.465A24.554,24.554,0,0,1,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1200" data-name="Groupe 1200" transform="translate(27.807 -19.394)">
          <path
            id="Tracé_950"
            data-name="Tracé 950"
            d="M0,0A17.56,17.56,0,0,1-15.364-8.87a17.562,17.562,0,0,1,0-17.742,1.4,1.4,0,0,0-.512-1.911,1.4,1.4,0,0,0-1.912.512,20.33,20.33,0,0,0,0,20.54A20.331,20.331,0,0,0,0,2.8,1.4,1.4,0,0,0,1.4,1.4,1.4,1.4,0,0,0,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1201" data-name="Groupe 1201" transform="translate(52.506 -37.647)">
          <path
            id="Tracé_951"
            data-name="Tracé 951"
            d="M0,0A13.022,13.022,0,0,0-.2,1.625L-.072,2.68"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1202" data-name="Groupe 1202" transform="translate(56.389 -49.179)">
          <path
            id="Tracé_952"
            data-name="Tracé 952"
            d="M0,0S5.485,5.891,5.485,13.157a17.488,17.488,0,0,1-1.6,7.016A18.136,18.136,0,0,1,6.121,18.1a17.543,17.543,0,0,0,.763-4.943C6.884,5.43,1.263-.7,1.025-.954L0-2.052l-1.024,1.1A23.646,23.646,0,0,0-3.133,1.828c.233.564.389,1.144.585,1.716A23.812,23.812,0,0,1,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1203" data-name="Groupe 1203" transform="translate(50.311 -30.899)">
          <path
            id="Tracé_953"
            data-name="Tracé 953"
            d="M0,0,1.2,1,1.474.059A16.529,16.529,0,0,1,.593-5.123a16.736,16.736,0,0,1,1.1-5.741c-.159-.774-.346-1.541-.582-2.3A19.17,19.17,0,0,0-.807-5.123,17.686,17.686,0,0,0,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1204" data-name="Groupe 1204" transform="translate(52.929 -43.939)">
          <path
            id="Tracé_954"
            data-name="Tracé 954"
            d="M0,0C.3-.607.6-1.178.911-1.7.716-2.268.56-2.849.326-3.412.018-2.943-.3-2.44-.62-1.873-.39-1.25-.18-.626,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1205" data-name="Groupe 1205" transform="translate(52.004 -41.763)">
          <path
            id="Tracé_955"
            data-name="Tracé 955"
            d="M0,0A21.656,21.656,0,0,1,.925-2.176C.745-2.8.535-3.426.305-4.048,0-3.51-.295-2.918-.582-2.3-.347-1.541-.159-.774,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1206" data-name="Groupe 1206" transform="translate(51.785 -30.839)">
          <path
            id="Tracé_956"
            data-name="Tracé 956"
            d="M0,0-.273.942.811,2.063A20.692,20.692,0,0,1,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1207" data-name="Groupe 1207" transform="translate(56.39 -46.956)">
          <path
            id="Tracé_957"
            data-name="Tracé 957"
            d="M0,0C1.492,2.01,4.084,6.183,4.084,10.935,4.084,15.667,1.49,19.85,0,21.866a25.028,25.028,0,0,1-2.266-3.8c-.143.466-.306.927-.474,1.389a24.4,24.4,0,0,1,2.07,3.158L0,22.332l.626.259A23.123,23.123,0,0,1,3.882,17.95a17.5,17.5,0,0,0,1.6-7.015C5.483,3.668,0-2.223,0-2.223A23.879,23.879,0,0,0-2.55,1.322c.21.616.448,1.227.612,1.852A26.161,26.161,0,0,1,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1208" data-name="Groupe 1208" transform="translate(51.785 -30.839)">
          <path
            id="Tracé_958"
            data-name="Tracé 958"
            d="M0,0A24.3,24.3,0,0,0,.649-4.128L.518-5.182a13.022,13.022,0,0,1,.2-1.625,24.283,24.283,0,0,0-.5-4.116,16.736,16.736,0,0,0-1.1,5.741A16.529,16.529,0,0,0,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1209" data-name="Groupe 1209" transform="translate(52.604 -28.768)">
          <path
            id="Tracé_959"
            data-name="Tracé 959"
            d="M0,0,1.044,1.262C1.212.8,1.375.339,1.518-.127a20.215,20.215,0,0,1-.874-2.2C.464-1.541.252-.766,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1210" data-name="Groupe 1210" transform="translate(54.452 -43.782)">
          <path
            id="Tracé_960"
            data-name="Tracé 960"
            d="M0,0C-.164-.625-.4-1.236-.612-1.852c-.31.518-.611,1.089-.911,1.7.224.786.39,1.573.537,2.362A20.689,20.689,0,0,1,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1211" data-name="Groupe 1211" transform="translate(52.604 -28.768)">
          <path
            id="Tracé_961"
            data-name="Tracé 961"
            d="M0,0C.252-.766.464-1.541.644-2.33A15.686,15.686,0,0,1-.17-6.2,24.3,24.3,0,0,1-.819-2.072,20.808,20.808,0,0,0-.008-.008Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1212" data-name="Groupe 1212" transform="translate(52.506 -37.647)">
          <path
            id="Tracé_962"
            data-name="Tracé 962"
            d="M0,0A16.467,16.467,0,0,1,.96-3.93C.813-4.719.647-5.505.423-6.292A21.889,21.889,0,0,0-.5-4.116,24.4,24.4,0,0,1,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1213" data-name="Groupe 1213" transform="translate(78.773 -34.723)">
          <path
            id="Tracé_963"
            data-name="Tracé 963"
            d="M0,0C-.346-.062-8.537-1.476-15.041,2.7l-1.222.944A22.269,22.269,0,0,1-17.28,6.464a15.732,15.732,0,0,1,3-2.587C-8.17-.052-.249,1.376-.249,1.376s-1.989,7.8-8.1,11.729c-4.5,2.9-9.968,2.879-12.543,2.672l.246.932-.337.064v.4c.588.048,1.272.09,2.1.09A21.11,21.11,0,0,0-7.594,14.281c6.5-4.178,8.616-12.217,8.7-12.558L1.479.266Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1214" data-name="Groupe 1214" transform="translate(57.414 -24.13)">
          <path
            id="Tracé_964"
            data-name="Tracé 964"
            d="M0,0,.374.9v.483a21.994,21.994,0,0,1,3.7-5.516A22.06,22.06,0,0,0,5.1-6.948,18.136,18.136,0,0,0,2.858-4.876,24.9,24.9,0,0,1,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1215" data-name="Groupe 1215" transform="translate(57.414 -24.13)">
          <path
            id="Tracé_965"
            data-name="Tracé 965"
            d="M0,0A24.9,24.9,0,0,0,2.858-4.876,23.118,23.118,0,0,0-.4-.236Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1216" data-name="Groupe 1216" transform="translate(57.788 -19.291)">
          <path
            id="Tracé_966"
            data-name="Tracé 966"
            d="M0,0,.091.345C2.667.553,8.13.569,12.635-2.327c6.112-3.929,8.1-11.728,8.1-11.728s-7.921-1.429-14.033,2.5a15.728,15.728,0,0,0-3,2.586A25.907,25.907,0,0,1,0-3.05ZM7.459-10.377c3.98-2.559,8.905-2.637,11.405-2.474-.889,2.346-3.007,6.789-6.987,9.347S2.975-.862.474-1.03c.888-2.345,3.005-6.789,6.985-9.347"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1217" data-name="Groupe 1217" transform="translate(57.788 -22.341)">
          <path
            id="Tracé_967"
            data-name="Tracé 967"
            d="M0,0A25.884,25.884,0,0,0,3.7-5.918,21.957,21.957,0,0,0,0-.4Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1218" data-name="Groupe 1218" transform="translate(54.661 -18.006)">
          <path
            id="Tracé_968"
            data-name="Tracé 968"
            d="M0,0,.234-.918A25.084,25.084,0,0,1-5.8-1.141L-6.885.094A25.1,25.1,0,0,0-2,.588C-1.078.588-.315.537.328.479V.059Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1219" data-name="Groupe 1219" transform="translate(34.124 -33.136)">
          <path
            id="Tracé_969"
            data-name="Tracé 969"
            d="M0,0S7.908-1.5,14.056,2.373a15.649,15.649,0,0,1,2.825,2.354l.1-.305a21.234,21.234,0,0,1-.8-2.184A15.79,15.79,0,0,0,14.8,1.19C8.266-2.928.086-1.437-.26-1.375l-1.476.28L-1.352.359C-1.262.7.923,8.718,7.462,12.838a16.919,16.919,0,0,0,3.124,1.518l1.1-1.126a15.63,15.63,0,0,1-3.476-1.576C2.06,7.781,0,0,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1220" data-name="Groupe 1220" transform="translate(47.265 -19.45)">
          <path
            id="Tracé_970"
            data-name="Tracé 970"
            d="M0,0-1.01,1.135c.513.144,1.018.3,1.52.4L1.591.3C1.067.218.541.132,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1221" data-name="Groupe 1221" transform="translate(44.71 -18.78)">
          <path
            id="Tracé_971"
            data-name="Tracé 971"
            d="M0,0C.517.193,1.03.321,1.545.465L2.555-.67C2.071-.788,1.586-.964,1.1-1.126Z"
            fill="currentColor"
          />
        </g>
      </g>
      <g id="Groupe_1223" data-name="Groupe 1223" transform="translate(51.005 -28.409)">
        <path id="Tracé_973" data-name="Tracé 973" d="M0,0,.425.444.1-.305Z" fill="currentColor" />
      </g>
      <g id="Groupe_1230" data-name="Groupe 1230" clipPath="url(#clip-path-drop-flower)">
        <g id="Groupe_1224" data-name="Groupe 1224" transform="translate(53.056 -25.949)">
          <path
            id="Tracé_974"
            data-name="Tracé 974"
            d="M0,0A23.655,23.655,0,0,1,1.932,3.3V2.723l.376-.9A25.311,25.311,0,0,1,.43-1.08Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1225" data-name="Groupe 1225" transform="translate(50.311 -30.899)">
          <path
            id="Tracé_975"
            data-name="Tracé 975"
            d="M0,0A21.339,21.339,0,0,0,.8,2.185L1.2,1Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1226" data-name="Groupe 1226" transform="translate(52.602 -28.76)">
          <path
            id="Tracé_976"
            data-name="Tracé 976"
            d="M0,0C-.164.5-.328,1-.521,1.486L.454,2.812l.43-1.08C.581,1.19.282.622,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1227" data-name="Groupe 1227" transform="translate(52.596 -28.776)">
          <path
            id="Tracé_977"
            data-name="Tracé 977"
            d="M0,0-1.084-1.122l-.4,1.184.32.749.652.69C-.322,1.014-.158.515.006.016Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1228" data-name="Groupe 1228" transform="translate(55.364 -24.13)">
          <path
            id="Tracé_978"
            data-name="Tracé 978"
            d="M0,0,.354-.217a24.4,24.4,0,0,0-2.07-3.159l-.162.477A25.311,25.311,0,0,0,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1229" data-name="Groupe 1229" transform="translate(52.602 -28.76)">
          <path
            id="Tracé_979"
            data-name="Tracé 979"
            d="M0,0C.282.622.581,1.19.884,1.732l.162-.477L0-.007Z"
            fill="currentColor"
          />
        </g>
      </g>
      <g id="Groupe_1231" data-name="Groupe 1231" transform="translate(52.602 -28.76)">
        <path id="Tracé_981" data-name="Tracé 981" d="M0,0,0-.007-.006-.015Z" fill="currentColor" />
      </g>
      <g id="Groupe_1232" data-name="Groupe 1232" transform="translate(54.895 -18.924)">
        <path
          id="Tracé_982"
          data-name="Tracé 982"
          d="M0,0-.234.918.094.977V-.006Z"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1233" data-name="Groupe 1233" transform="translate(57.788 -17.95)">
        <path
          id="Tracé_983"
          data-name="Tracé 983"
          d="M0,0,.338-.064.091-1,0-1Z"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1234" data-name="Groupe 1234" transform="translate(57.788 -18.951)">
        <path id="Tracé_984" data-name="Tracé 984" d="M0,0,.091,0,0-.339Z" fill="currentColor" />
      </g>
      <g id="Groupe_1243" data-name="Groupe 1243" clipPath="url(#clip-path-drop-flower)">
        <g id="Groupe_1235" data-name="Groupe 1235" transform="translate(54.989 -22.341)">
          <path
            id="Tracé_985"
            data-name="Tracé 985"
            d="M0,0A26.486,26.486,0,0,1-2.189-3.089l-.458.927A24.452,24.452,0,0,1-.484,2.046a24.832,24.832,0,0,1-4.631-.127L-6.132,3.194a25.032,25.032,0,0,0,6.038.223L0,3.047Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1236" data-name="Groupe 1236" transform="translate(36.008 -31.95)">
          <path
            id="Tracé_986"
            data-name="Tracé 986"
            d="M0,0C2.507-.187,7.424-.152,11.427,2.37a14.486,14.486,0,0,1,3.009,2.615c.209-.476.383-.96.561-1.444a15.5,15.5,0,0,0-2.825-2.354C6.024-2.687-1.884-1.187-1.884-1.187S.176,6.594,6.325,10.468A15.714,15.714,0,0,0,9.8,12.044l1.01-1.155a14.551,14.551,0,0,1-3.74-1.6C3.068,6.761.909,2.338,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1237" data-name="Groupe 1237" transform="translate(48.342 -20.702)">
          <path
            id="Tracé_987"
            data-name="Tracé 987"
            d="M0,0-1.076,1.253c.54.131,1.066.218,1.591.3L1.533.28C1.029.219.525.1,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1238" data-name="Groupe 1238" transform="translate(51.412 -25.76)">
          <path
            id="Tracé_988"
            data-name="Tracé 988"
            d="M0,0,.929,1.257,1.387.33C1.1-.132.828-.627.546-1.164Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1239" data-name="Groupe 1239" transform="translate(51.005 -28.409)">
          <path
            id="Tracé_989"
            data-name="Tracé 989"
            d="M0,0C-.178.484-.352.968-.561,1.444l.968,1.2L.953,1.485.424.444Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1240" data-name="Groupe 1240" transform="translate(46.818 -21.06)">
          <path
            id="Tracé_990"
            data-name="Tracé 990"
            d="M0,0-1.01,1.154c.488.162.974.339,1.457.456L1.523.358C1.018.257.514.149,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1241" data-name="Groupe 1241" transform="translate(53.056 -25.949)">
          <path
            id="Tracé_991"
            data-name="Tracé 991"
            d="M0,0-.257.519A26.7,26.7,0,0,0,1.932,3.608V3.3A23.655,23.655,0,0,0,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1242" data-name="Groupe 1242" transform="translate(52.081 -27.275)">
          <path
            id="Tracé_992"
            data-name="Tracé 992"
            d="M0,0-.123.35c.283.538.557,1.032.841,1.5l.257-.519Z"
            fill="currentColor"
          />
        </g>
      </g>
      <g id="Groupe_1244" data-name="Groupe 1244" transform="translate(51.959 -26.924)">
        <path
          id="Tracé_994"
          data-name="Tracé 994"
          d="M0,0,.122-.35l-.651-.69Z"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1245" data-name="Groupe 1245" transform="translate(54.895 -18.924)">
        <path id="Tracé_995" data-name="Tracé 995" d="M0,0,.094-.006V-.37Z" fill="currentColor" />
      </g>
      <g id="Groupe_1248" data-name="Groupe 1248" clipPath="url(#clip-path-drop-flower)">
        <g id="Groupe_1246" data-name="Groupe 1246" transform="translate(54.989 -17.527)">
          <path
            id="Tracé_996"
            data-name="Tracé 996"
            d="M0,0V16.128a1.4,1.4,0,0,0,2.8,0V-.022C2.175-.074,1.7-.128,1.431-.169,1.18-.127.692-.063,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1247" data-name="Groupe 1247" transform="translate(57.014 -24.365)">
          <path
            id="Tracé_997"
            data-name="Tracé 997"
            d="M0,0-.626-.259-1.3.019c.252.466.451.892.651,1.319C-.448.9-.249.473,0,0"
            fill="currentColor"
          />
        </g>
      </g>
      <g id="Groupe_1249" data-name="Groupe 1249" transform="translate(57.033 -21.504)">
        <path id="Tracé_999" data-name="Tracé 999" d="M0,0,.1.244.346-.371Z" fill="currentColor" />
      </g>
      <g id="Groupe_1254" data-name="Groupe 1254" clipPath="url(#clip-path-drop-flower)">
        <g id="Groupe_1250" data-name="Groupe 1250" transform="translate(56.649 -17.734)">
          <path
            id="Tracé_1000"
            data-name="Tracé 1000"
            d="M0,0-.229.037c.268.041.744.1,1.368.147v-.4Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1251" data-name="Groupe 1251" transform="translate(57.033 -21.504)">
          <path
            id="Tracé_1001"
            data-name="Tracé 1001"
            d="M0,0,.347-.371l.408-.868v-.483l-.374-.9C-.172-1.894-.55-1.464-.606-1.4c.24.518.438.977.606,1.4"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1252" data-name="Groupe 1252" transform="translate(56.369 -23.028)">
          <path
            id="Tracé_1002"
            data-name="Tracé 1002"
            d="M0,0,.058.12C.114.06.492-.37,1.045-1.1l-.4-.236C.4-.865.2-.432,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1253" data-name="Groupe 1253" transform="translate(57.38 -21.875)">
          <path
            id="Tracé_1003"
            data-name="Tracé 1003"
            d="M0,0-.247.615c.383,1,.584,1.7.608,1.793l.047.176V-.466l-.375.43Z"
            fill="currentColor"
          />
        </g>
      </g>
      <g id="Groupe_1255" data-name="Groupe 1255" transform="translate(57.38 -21.875)">
        <path
          id="Tracé_1005"
          data-name="Tracé 1005"
          d="M0,0,.033-.036.409-.467v-.4Z"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1256" data-name="Groupe 1256" transform="translate(55.622 -21.253)">
        <path
          id="Tracé_1006"
          data-name="Tracé 1006"
          d="M0,0,.1-.267l-.363-.39L-.294-.7Z"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1262" data-name="Groupe 1262" clipPath="url(#clip-path-drop-flower)">
        <g id="Groupe_1257" data-name="Groupe 1257" transform="translate(56.14 -17.74)">
          <path
            id="Tracé_1007"
            data-name="Tracé 1007"
            d="M0,0-1.151-.207v.42C-.459.15.028.086.28.044Z"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1258" data-name="Groupe 1258" transform="translate(55.364 -24.13)">
          <path
            id="Tracé_1008"
            data-name="Tracé 1008"
            d="M0,0-.375.9v.572l.338.7L0,2.219l.364.39c.165-.431.361-.894.6-1.416C.875,1.1.524.693,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1259" data-name="Groupe 1259" transform="translate(56.325 -22.937)">
          <path
            id="Tracé_1009"
            data-name="Tracé 1009"
            d="M0,0,.044-.091c-.2-.427-.4-.853-.651-1.319l-.354.217C-.437-.5-.086-.093,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1260" data-name="Groupe 1260" transform="translate(56.389 -19.117)">
          <path
            id="Tracé_1010"
            data-name="Tracé 1010"
            d="M0,0S.243-.881.744-2.143l-.1-.244L0-1.7-.661-2.4l-.106.267A22.687,22.687,0,0,1,0,.008S-.527.1-1.4.186v.983l1.152.207.279.044.23-.037L1.4,1.167v-1C.527.092,0,0,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1261" data-name="Groupe 1261" transform="translate(56.389 -22.864)">
          <path
            id="Tracé_1011"
            data-name="Tracé 1011"
            d="M0,0-.063-.072C-.3.449-.5.913-.661,1.344L0,2.052.644,1.36C.477.933.278.474.039-.043Z"
            fill="currentColor"
          />
        </g>
      </g>
      <g id="Groupe_1263" data-name="Groupe 1263" transform="translate(56.326 -22.937)">
        <path
          id="Tracé_1013"
          data-name="Tracé 1013"
          d="M0,0,.063.072.1.029.043-.091Z"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1266" data-name="Groupe 1266" clipPath="url(#clip-path-drop-flower)">
        <g id="Groupe_1264" data-name="Groupe 1264" transform="translate(57.132 -21.26)">
          <path
            id="Tracé_1014"
            data-name="Tracé 1014"
            d="M0,0C-.5,1.262-.744,2.143-.744,2.143s.528.092,1.4.166v-.34L.609,1.792C.584,1.7.384,1,0,0"
            fill="currentColor"
          />
        </g>
        <g id="Groupe_1265" data-name="Groupe 1265" transform="translate(54.989 -22.341)">
          <path
            id="Tracé_1015"
            data-name="Tracé 1015"
            d="M0,0V3.047l.043-.169c.024-.092.217-.787.59-1.79L.339.39Z"
            fill="currentColor"
          />
        </g>
      </g>
      <g id="Groupe_1267" data-name="Groupe 1267" transform="translate(54.989 -22.342)">
        <path
          id="Tracé_1017"
          data-name="Tracé 1017"
          d="M0,0,.338.391,0-.312Z"
          fill="currentColor"
        />
      </g>
      <g id="Groupe_1269" data-name="Groupe 1269" clipPath="url(#clip-path-drop-flower)">
        <g id="Groupe_1268" data-name="Groupe 1268" transform="translate(54.989 -19.294)">
          <path
            id="Tracé_1018"
            data-name="Tracé 1018"
            d="M0,0V.364C.873.283,1.4.186,1.4.186S1.148-.7.633-1.959c-.373,1-.566,1.7-.59,1.79Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DropFlower;
